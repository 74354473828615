import React, { useState, useEffect } from "react";
import API from "../../services/api-service"
import { useLocation } from "react-router-dom";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Modal from "../../components/Modal/Modal right-left/index"
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'
import Select2 from "../../components/Entradas/Select";
import { Button, Table } from "react-bootstrap";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from "@material-ui/icons/Edit";
import { Checkbox, FormControlLabel } from "@mui/material";
import Tooltip from "react-tooltip";
import {
    ContenedorTitulo,
    Main,
    ContenedorButton,
    ContenedorEditar,
    ContenedorFecha
} from "../Usuario Cuentas/UsuariosElements";

const EditarUsuario = () => {

    var history = useHistory();
    const [postUsuario, setUsuario] = useState(null);
    const [postNombre, setNombre] = useState(null);
    const [postPassword, setPassword] = useState(null);
    const [postConfirmarPass, setConfirmarPass] = useState(null);
    const [postEmail, setEmail] = useState(null);
    const [postEstado, setEstado] = useState(null);
    const [postRol, setPostRol] = useState(null);
    const [organizaciones, setOrganizaciones] = useState([]);
    const [organizacion, setOrganizacion] = useState(null);
    const [rol, setRol] = useState(null);
    const [localizaciones, setLocalizaciones] = useState([]);
    const [localizacionesNombres, setLocalizacionesNombres] = useState([]);
    const [listaOrganizaciones, setListaOrganizaciones] = useState([]);
    const [organizacionesDisponibles, setOrganizacionesDisponibles] = useState(null);
    const [showAddOrganization, setShowAddOrganization] = useState(false);
    const [showEditOrganization, setShowEditOrganization] = useState(false);
    const query = new URLSearchParams(useLocation().search);
    const getUset = query.get("count");
    const getEdit = query.get("edit");
    const isEdit = (getEdit === "true")

    useEffect(() => {
        if (isEdit) {
            API.obtenerUsuariosIndividual(getUset).then(
                (respuesta) => {
                    setUsuario(respuesta[0].usuario)
                    setEmail(respuesta[0].email)
                    setNombre(respuesta[0].nombre)
                    setEstado(respuesta[0].estado)
                    setPostRol(respuesta[0].rol)
                    setOrganizaciones(respuesta[0].organizaciones)
                },
                (error) => {
                    console.log(error);
                }
            );
        }

        API.getListEmpresas({ organizacion: "constecoin", estado: "habilitado" })
            .then((respuesta) => { setListaOrganizaciones(respuesta) })
            .catch(err => { console.log(err) })
    }, []);

    useEffect(() => {
        setLocalizacionesNombres([]);
        setLocalizaciones([]);
        API.adquirirConfiguracionesEmpresa({ organizacion: organizacion })
            .then((res) => {
                const localizacionesNombreAux = res.localizaciones.map(item => {
                    const estadoCheckbox = item.estado == "habilitado" ? true : false;
                    return { localiza: item.localiza, nombre: item.nombre, estadoCheckbox: estadoCheckbox }
                })
                setLocalizacionesNombres(localizacionesNombreAux);
                const localizacionesAux = res.localizaciones.map(item => {
                    return { localiza: item.localiza, estado: item.estado }
                })
                setLocalizaciones(localizacionesAux)
            })
            .catch((error) => console.log('Error: ', error));
    }, [organizacion]);

    const validarDatos = () => {
        if (getUset != null && postNombre != null && postEmail != null && postRol != postEstado && organizaciones.length > 0) {
            if (!isEdit) {
                if (postPassword === postConfirmarPass && postConfirmarPass != null) {
                    sendData()
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Contraseña",
                        text: "Las contraseñas no coinciden o alguno de los campos está vacío"
                    });
                }
            } else {
                sendData()
            }
        } else {
            Swal.fire({
                icon: "error",
                title: "Campos Vacios",
                text: "Por favor, complete todos los campos"
            });
        }
    }

    function sendData() {

        Swal.fire({
            title: '¿Seguro que quiere guardar los cambios?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Si',
            denyButtonText: `No`,
        }).then((result) => {
            if (result.isConfirmed) {
                if (isEdit) {
                    const datosActualizados = {
                        id: getUset,
                        nombre: postNombre,
                        email: postEmail,
                        rol: postRol,
                        estado: postEstado,
                        organizaciones: organizaciones
                    }
                    API.editarUsuario(datosActualizados).then((response) => {
                        Swal.fire({
                            icon: "success",
                            text: "Usuario actualizado correctamente"
                        });
                        history.goBack()
                    }).catch((error) => {
                        Swal.fire({
                            icon: "error",
                            text: "No se pudo actualizar el usuario"
                        });
                    })
                } else {
                    console.log('entro a crear usuario')
                    console.log(postRol)
                    API.crearUsuario(postUsuario, postPassword, postEmail, organizaciones, postRol, postNombre, postEstado).then((respues) => {
                        Swal.fire({
                            icon: "success",
                            text: "Usuario creado correctamente"
                        });
                        history.goBack()
                    }).catch((error) => {
                        Swal.fire({
                            icon: "error",
                            text: "No se pudo actualizar el usuario"
                        });
                    })
                }
            } else if (result.isDenied) {
                Swal.fire('Usuario no editado', '', 'info')
            }
        })
    }

    const eliminarOrganizacion = ((organiza) => {
        setOrganizaciones(organizaciones.filter(org => org.organizacion !== organiza.organizacion))
    })

    const obtenerOrganizacionesDisponibles = (() => {
        if (listaOrganizaciones.length !== 0) {
            const list = listaOrganizaciones.filter(item1 =>
                !organizaciones.some(item2 => item2.organizacion === item1.organizacion)
            );
            const organizacionesAux = list.map((item) => (
                { value: item.organizacion, label: item.nombre }
            ))
            setOrganizacion(null)
            setRol(null)
            setLocalizaciones([])
            setOrganizacionesDisponibles(organizacionesAux)
            setShowAddOrganization(true);
        }
    })

    const obtenerOrganizacion = (org) => {
        const localizacionesNombreAux = org.localizaciones.map(item => {
            const estadoCheckbox = item.estado == "habilitado" ? true : false;
            return { localiza: item.localiza, nombre: item.nombre, estadoCheckbox: estadoCheckbox }
        })
        setLocalizaciones(org.localizaciones)
        setOrganizacion(org.organizacion)
        setRol(org.rol)
        setLocalizacionesNombres(localizacionesNombreAux);
        console.log("localizacionesNombre", localizacionesNombres)
        console.log("localizaciones", localizaciones)
        setShowEditOrganization(true);
    }

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setLocalizacionesNombres(prevState =>
            prevState.map(item =>
                item.localiza === name
                    ? { ...item, estadoCheckbox: checked }
                    : item
            )
        );
        setLocalizaciones(prevState =>
            prevState.map(item =>
                item.localiza === name
                    ? { ...item, estado: checked ? "habilitado" : "inhabilitado" }
                    : item
            )
        );
    };

    const agregarOrganizacion = () => {
        if (organizacion === null) {
            Swal.fire({
                text: "Seleccione una organización",
                icon: "warning"
            });
        } else if (rol === null) {
            Swal.fire({
                text: "Seleccione un rol",
                icon: "warning"
            });
        } else {
            setOrganizaciones(prevState => [
                ...prevState,
                { organizacion: organizacion, rol: rol, localizaciones: localizaciones }
            ]);
            setShowAddOrganization(false)
        }
    }

    return (
        <>
            <Main>
                <ContenedorFecha>
                    <Button onClick={() => { history.goBack() }}>Atras</Button>
                </ContenedorFecha>
                <ContenedorTitulo>
                    {isEdit ? 'Editar Usuario' : 'Crear Usuario'}
                </ContenedorTitulo>
                <div style={{ gap: '1rem', display: 'flex', flexDirection: 'column', marginTop: '1rem', marginBottom: '1rem' }}>
                    <ContenedorEditar>
                        {isEdit ? <>
                            <TextField
                                disabled
                                id="outlined-disabled"
                                label="Usuario"
                                type="text"
                                value={postUsuario}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => setUsuario(e.target.value)}
                            />
                        </> : <>
                            <TextField
                                id="outlined-disabled"
                                label="Usuario"
                                type="text"
                                value={postUsuario}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => setUsuario(e.target.value)}
                            />
                        </>}
                    </ContenedorEditar>
                    <ContenedorEditar>
                        <TextField
                            id="outlined-disabled"
                            label="Nombre"
                            type="text"
                            value={postNombre}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => setNombre(e.target.value)}
                        />
                    </ContenedorEditar>
                    <ContenedorEditar>
                        <TextField
                            id="outlined-disabled"
                            label="Email"
                            value={postEmail}
                            type="text"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </ContenedorEditar>
                    {!isEdit ? <>
                        <ContenedorEditar>
                            <TextField
                                id="outlined-disabled"
                                label="Contraseña"
                                type="password"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </ContenedorEditar>
                        <ContenedorEditar>
                            <TextField
                                id="outlined-disabled"
                                label="Confirmar Contraseña"
                                type="password"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => setConfirmarPass(e.target.value)}
                            />
                        </ContenedorEditar>
                    </> : <></>}

                    {isEdit ? <>
                        {postEstado != null ? <>
                            <div style={{ width: '14rem' }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        defaultValue={postEstado}
                                        label="Estado"
                                        onChange={(e) => setEstado(e.target.value)}
                                    >
                                        <MenuItem value="habilitado">Habilitado</MenuItem>
                                        <MenuItem value="inhabilitado">Inhabilitado</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </> : null}
                        {postRol != null ? <>
                            <div style={{ width: '14rem' }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Rol</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        defaultValue={postRol}
                                        label="Estado"
                                        onChange={(e) => setRol(e.target.value)}
                                    >

                                        <MenuItem value="usuario">Usuario</MenuItem>
                                        <MenuItem value="administrador">Administrador</MenuItem>
                                        <MenuItem value="visualizador">Visualizador</MenuItem>

                                    </Select>
                                </FormControl>
                            </div>
                        </> : null}
                    </> : <>
                        <div style={{ width: '14rem' }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={postEstado}
                                    label="Estado"
                                    onChange={(e) => setEstado(e.target.value)}
                                >
                                    <MenuItem value="habilitado">Habilitado</MenuItem>
                                    <MenuItem value="inhabilitado">Inhabilitado</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ width: '14rem' }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Rol</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={postRol}
                                    label="Estado"
                                    onChange={(e) => setPostRol(e.target.value)}
                                >

                                    <MenuItem value="usuario">Usuario</MenuItem>
                                    <MenuItem value="administrador">Administrador</MenuItem>
                                    <MenuItem value="visualizador">Visualizador</MenuItem>

                                </Select>
                            </FormControl>
                        </div>
                    </>}
                </div>

                <Table responsive>
                    <thead>
                        <tr class="table-active">
                            <th>Organización</th>
                            <th>Rol</th>
                            <th>Localización (Estado)</th>
                            <th>Acción</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(organizaciones) ?
                            <>
                                {organizaciones.map((item) => (
                                    <tr>
                                        <td>{item.organizacion}</td>
                                        <td>{item.rol}</td>
                                        <td>
                                            {item.localizaciones.map((itemLocalizaciones) => (
                                                <p>{itemLocalizaciones.localiza} ({itemLocalizaciones.estado})</p>
                                            ))}
                                        </td>
                                        <td>
                                            <Tooltip
                                                id="editar-tooltip"
                                                place="top"
                                                effect="solid"
                                            >
                                                Editar Usuario
                                            </Tooltip>
                                            <EditIcon
                                                data-tip
                                                data-for="editar-tooltip"
                                                style={{ marginRight: '1rem', color: "#007bff", cursor: "pointer" }}
                                                onClick={() => obtenerOrganizacion(item)}
                                            />
                                            <Tooltip
                                                id="eliminar-tooltip"
                                                place="top"
                                                effect="solid"
                                            >
                                                Eliminar Organización
                                            </Tooltip>
                                            <DeleteIcon
                                                data-tip
                                                data-for="eliminar-tooltip"
                                                style={{ marginRight: '1rem', color: "#dc3545", cursor: "pointer" }}
                                                onClick={() => eliminarOrganizacion(item)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </> : <>No hay Datos</>}
                    </tbody>
                </Table>
                <Button
                    variant="primary"
                    size="sm"
                    onClick={() => obtenerOrganizacionesDisponibles()}
                >
                    + Agregar Organización
                </Button>
                <ContenedorButton>
                    <Button onClick={() => { validarDatos() }}>
                        {isEdit ? 'Guardar Cambios' : 'Crear Usuario'}
                    </Button>
                </ContenedorButton>
            </Main>

            {/* AGREGAR ORGANIZACION */}
            <Modal isOpen={showAddOrganization} onClose={() => setShowAddOrganization(false)} >
                <ContenedorTitulo>Agregar Organización</ContenedorTitulo>
                <div style={{ margin: "2rem 0 0 0" }}>
                    <Select2
                        message="Organización"
                        data={organizacionesDisponibles}
                        value={organizacion}
                        setFunction={setOrganizacion}
                        id="outlined-disabled"
                    />
                </div>
                <div style={{ margin: "0.5rem 0 1rem 0" }}>
                    <Select2
                        message="Rol"
                        data={[{ value: "usuario", label: "Usuario" }, { value: "administrador", label: "Administrador" }, { value: "visualizador", label: "Visualizador" }]}
                        value={rol}
                        setFunction={setRol}
                        id="outlined-disabled"
                    />
                </div>
                <div style={{ width: 'min', marginBottom: '1rem' }}>
                    <Table responsive>
                        <thead>
                            <tr class="table-active">
                                <th>Localización</th>
                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(localizaciones.length > 0 && localizacionesNombres.length > 0) ? <>
                                {localizacionesNombres.map((item, index) => (
                                    <>
                                        <tr key={index}>
                                            <td>{item.nombre}</td>
                                            <td>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={item.estadoCheckbox}
                                                            onChange={handleCheckboxChange}
                                                            name={item.localiza}
                                                        />
                                                    }
                                                    label={localizaciones.find(item2 => item2.localiza === item.localiza).estado}
                                                />
                                            </td>
                                        </tr>
                                    </>
                                ))}
                            </> : null}
                        </tbody>
                    </Table>
                </div>
                <Button
                    variant="secondary"
                    onClick={() => setShowAddOrganization(false)}
                    style={{ marginRight: '1rem' }}
                >
                    Cancelar
                </Button>
                <Button
                    variant="primary"
                    onClick={() => agregarOrganizacion()}
                >
                    Agregar
                </Button>
            </Modal>

            {/* EDITAR ORGANIZACION */}
            <Modal isOpen={showEditOrganization} onClose={() => setShowEditOrganization(false)} >
                <ContenedorTitulo>Editar Organización</ContenedorTitulo>
                <div style={{ margin: "1rem 0 1rem 1rem" }}>
                    <TextField
                        disabled
                        id="outlined-disabled"
                        label="Organización"
                        type="text"
                        value={organizacion}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => setUsuario(e.target.value)}
                    />
                </div>
                <div style={{ margin: "0.5rem 0 1rem 0" }}>
                    <Select2
                        message="Rol"
                        data={[{ value: "usuario", label: "Usuario" }, { value: "administrador", label: "Administrador" }, { value: "visualizador", label: "Visualizador" }]}
                        value={rol}
                        setFunction={setRol}
                        id="outlined-disabled"
                    />
                </div>
                <div style={{ width: 'min', marginBottom: '1rem' }}>
                    <Table responsive>
                        <thead>
                            <tr class="table-active">
                                <th>Localización</th>
                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(localizaciones.length > 0 && localizacionesNombres.length > 0) ? <>
                                {localizacionesNombres.map((item, index) => (
                                    <>
                                        <tr key={index}>
                                            <td>{item.nombre}</td>
                                            <td>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={item.estadoCheckbox}
                                                            onChange={handleCheckboxChange}
                                                            name={item.localiza}
                                                        />
                                                    }
                                                    label={localizaciones.find(item2 => item2.localiza === item.localiza).estado}
                                                />
                                            </td>
                                        </tr>
                                    </>
                                ))}
                            </> : null}
                        </tbody>
                    </Table>
                </div>
                <Button
                    variant="secondary"
                    onClick={() => setShowEditOrganization(false)}
                    style={{ marginRight: '1rem' }}
                >
                    Cancelar
                </Button>
                <Button
                    variant="primary"
                    onClick={() => console.log(localizacionesNombres)}
                >
                    Agregar
                </Button>
            </Modal>
        </>
    )
}

export default EditarUsuario