import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Auth from "../../services/auth-service";
import API from "../../services/api-service";
import { Main, ContenedorDatos, ContenedorFechas, ContenedorTitulo } from "../Historico/ElementosStyles";
import { Button, Table } from "react-bootstrap";
import ModalCentro from "../../components/Modal/Modal Centro"
import { useHistory } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "react-tooltip";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Swal from 'sweetalert2'

const AdministracionUsuarios = () => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const organizacion = query.get("organizacion");
  const localizacion = query.get("localizacion");
  const organizaciones = Auth.getOrganizaciones();
  const rol = Auth.getRol();
  const [dispositivos, setDispositivos] = useState([]);
  const [currentDevicesPage, setCurrentDevicesPage] = useState(1);
  const [showModalView, setShowModalView] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [usuario, setUsuario] = useState({});
  const [flagGetData, setFlagGetData] = useState(true)
  const itemsPerPage = 10;
  const totalDevices = dispositivos.length;
  const totalPagesDevices = Math.ceil(totalDevices / itemsPerPage);

  useEffect(() => {
    API.obtenerUsuariosGeneral(rol, organizacion)
      .then((res) => {
        setDispositivos(res);
      })
      .catch((error) => console.error(error));
  }, [flagGetData]);

  const paginateDevices = (pageNumber) => setCurrentDevicesPage(pageNumber);

  const handleNextDevices = () => {
    if (currentDevicesPage < totalPagesDevices)
      paginateDevices(currentDevicesPage + 1);
  };

  const handlePreviousDevices = () => {
    if (currentDevicesPage > 1) paginateDevices(currentDevicesPage - 1);
  };

  const renderPaginationButtons = (
    currentPage,
    totalPages,
    paginate,
    handleNext,
    handlePrevious
  ) => {
    const buttons = [];
    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(totalPages, currentPage + 1);

    if (startPage > 1) {
      buttons.push(
        <Button
          key={1}
          onClick={() => paginate(1)}
          size="sm"
          style={{
            margin: "0 3px",
            backgroundColor: "#fff",
            color: "#007bff",
          }}
        >
          1
        </Button>
      );
      if (startPage > 2) buttons.push(<span key="dots1">...</span>);
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <Button
          key={i}
          onClick={() => paginate(i)}
          size="sm"
          style={{
            margin: "0 3px",
            backgroundColor: currentPage === i ? "#007bff" : "#fff",
            color: currentPage === i ? "#fff" : "#007bff",
          }}
        >
          {i}
        </Button>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) buttons.push(<span key="dots2">...</span>);
      buttons.push(
        <Button
          key={totalPages}
          onClick={() => paginate(totalPages)}
          size="sm"
          style={{
            margin: "0 3px",
            backgroundColor: "#fff",
            color: "#007bff",
          }}
        >
          {totalPages}
        </Button>
      );
    }

    return (
      <div>
        <Button
          onClick={handlePrevious}
          disabled={currentPage === 1}
          size="sm"
          style={{
            margin: "0 5px",
            backgroundColor: "#fff",
            color: "#007bff",
          }}
        >
          &lt;
        </Button>
        {buttons}
        <Button
          onClick={handleNext}
          disabled={currentPage === totalPages}
          size="sm"
          style={{
            margin: "0 5px",
            backgroundColor: "#fff",
            color: "#007bff",
          }}
        >
          &gt;
        </Button>
      </div>
    );
  };

  const currentDevices = dispositivos.slice(
    (currentDevicesPage - 1) * itemsPerPage,
    currentDevicesPage * itemsPerPage
  );

  const mostrarUsuario = (usuario) => {
    setUsuario(usuario)
    setShowModalView(true)
  }

  const editarUsuario = (usuario) => {
    history.push(
      `/admin/iotUsersEdit?count=${usuario._id}&edit=${true}`
    );
  }

  const agregarUsuario = () => {
    history.push(
      `/admin/iotUsersEdit?count=${null}&edit=${false}`
    );
  }

  function Borrar(user) {
    Swal.fire({
      title: '¿Seguro que quieres eliminar este Usuario?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Eliminar',
      denyButtonText: `No eliminar`,
    }).then((result) => {
      if (result.isConfirmed) {
        API.eliminarUsuario(user._id).then((respuesta) => {
          Swal.fire({
            icon: "success",
            text: "Usuario Eliminado"
          });
          setFlagGetData(!flagGetData)
        }).catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "No se puso elimiar el usuario, intente mas tarde"
          });
        })
      }
    })
  }

  return (
    <>
      <Main>
        <ContenedorFechas>
          <Button onClick={() => { history.goBack() }}>Atras</Button>
        </ContenedorFechas>
        <ContenedorTitulo>Usuarios</ContenedorTitulo>
        <div style={{ margin: '1rem 0 0 1rem' }}>
          <Button onClick={() => agregarUsuario()}>Crear Usuario</Button>
        </div>
        <ContenedorDatos>
          <Table responsive>
            <thead>
              <tr class="table-active">
                <th>Usuario</th>
                <th>Nombre</th>
                <th>Email</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              {currentDevices.map((dispositivo, index) => (
                <tr key={index}>
                  <td>{dispositivo.usuario}</td>
                  <td>{dispositivo.nombre}</td>
                  <td>{dispositivo.email}</td>
                  <td>
                    <Tooltip
                      id="ver-tooltip"
                      place="top"
                      effect="solid"
                    >
                      Información del Usuario
                    </Tooltip>
                    <VisibilityIcon
                      data-tip
                      data-for="ver-tooltip"
                      style={{ marginRight: '1rem', color: "#28a745", cursor: "pointer" }}
                      onClick={() => mostrarUsuario(dispositivo)}
                    />
                    <Tooltip
                      id="editar-tooltip"
                      place="top"
                      effect="solid"
                    >
                      Editar Usuario
                    </Tooltip>
                    <EditIcon
                      data-tip
                      data-for="editar-tooltip"
                      style={{ marginRight: '1rem', color: "#007bff", cursor: "pointer" }}
                      onClick={() => editarUsuario(dispositivo)}
                    />
                    <Tooltip
                      id="eliminar-tooltip"
                      place="top"
                      effect="solid"
                    >
                      Eliminar Usuario
                    </Tooltip>
                    <DeleteIcon
                      data-tip
                      data-for="eliminar-tooltip"
                      style={{ marginRight: '1rem', color: "#dc3545", cursor: "pointer" }}
                      onClick={() => Borrar(dispositivo)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {renderPaginationButtons(
            currentDevicesPage,
            totalPagesDevices,
            paginateDevices,
            handleNextDevices,
            handlePreviousDevices
          )}
        </ContenedorDatos>
      </Main>

      <div>
        <ModalCentro isOpen={showModalView}>
          <Button style={{ position: "absolute", top: "10px", right: "10px" }} onClick={() => setShowModalView(false)}>
            X
          </Button>
          <div>
            <p><b>Usuario:</b> {usuario.usuario}</p>
            <p><b>Nombre:</b> {usuario.nombre}</p>
            <p><b>Correo:</b> {usuario.email}</p>
            <p><b>Rol:</b> {usuario.rol}</p>
            <p><b>Estado:</b> {usuario.estado}</p>
          </div>
          <Table responsive>
            <thead>
              <tr class="table-active">
                <th>Organización</th>
                <th>Rol</th>
                <th>Localización (Estado)</th>
              </tr>
            </thead>
            <tbody>
              {(usuario.organizaciones) ?
                <>
                  {usuario.organizaciones.map((item) => (
                    <tr>
                      <td>{item.organizacion}</td>
                      <td>{item.rol}</td>
                      <td>
                        {item.localizaciones.map((itemLocalizaciones) => (
                          <p>{itemLocalizaciones.localiza} ({itemLocalizaciones.estado})</p>
                        ))}
                      </td>
                    </tr>
                  ))}
                </> : <>No hay Datos</>}
            </tbody>
          </Table>
        </ModalCentro>
      </div>
    </>
  );
};

export default AdministracionUsuarios;
