import { Map, Marker, GoogleApiWrapper, InfoWindow } from "google-maps-react";
import React, { useState, Fragment, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  BotonDatos,
} from "./mapaElement";
import { ContenedorFechas } from "../Historico/ElementosStyles"
import { Button } from "react-bootstrap";
import InfoWindowEx from "./window"
const YOUR_GOOGLE_API_KEY_GOES_HERE = "AIzaSyBYNDUEf0g8OQPJfiEW7ybizYPiMN_7XOU";

const Mapa = (props) => {

  const [lat, setLat] = useState("")
  const [long, setLong] = useState("")
  const [selectedElement, setSelectedElement] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [showInfoWindow, setInfoWindowFlag] = useState(true);
  const [zoom, setZoom] = useState(12)
  const [probandooo, setProbando] = useState(false)

  let history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(useLocation().search);
  const obteOrganizacion = query.get("organizacion");
  const localizacion = query.get("localizacion")
  const latitudQuery = query.get("lat");
  const longitudQuery = query.get("long");

  useEffect(() => {
    if (latitudQuery && longitudQuery) {
      setLat(parseFloat(latitudQuery));
      setLong(parseFloat(longitudQuery));
      setZoom(18)
    } else {
      fetch("https://ipapi.co/json/")
        .then((response) => response.json())
        .then((data) => {
          setLat(data.latitude);
          setLong(data.longitude)
        })
        .catch((error) => {
          console.error("Error al obtener la ubicación:", error);
        });
    }
  }, [location.search, latitudQuery, longitudQuery]);

  function probando(e) {
    let valor
    if (localizacion === "sto_epconst_loca_2") {
      valor = e.idSirena
      valor = valor.replace("sto/", "");
    } else if (localizacion === "iba_gad_loca_1") {
      valor = e.idSirena
    } else {
      valor = e.deviceID
    }
    let url = `/admin/viewDevicewt32?id=${valor}&organizacion=${obteOrganizacion}&localizacion=${localizacion}`
    history.push(url);
  }

  const atras = () => {
    history.goBack();
  };

  return (
    <>
      {(latitudQuery && longitudQuery) ? (
        <>
          <ContenedorFechas>
            <Button onClick={(e) => atras()}>Atras</Button>
          </ContenedorFechas>
        </>
      ) : null}

      {(lat !== "" && long !== "") ? (
        <Map
          google={window.google}
          initialCenter={{
            lat: lat,
            lng: long,
          }}
          style={props.styleMapa}
          zoom={zoom}
        >
          {props.devices.map((device) => {
            const iconColor = device.reportado
              ? "data:image/svg+xml;utf-8, \
              <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 48 48'> \
              <path fill='%2300FF00' stroke='%23008000' stroke-width='2' d='M24 4C15.2 4 8 11.2 8 20c0 9 8 16 16 24 8-8 16-15 16-24 0-8.8-7.2-16-16-16zm0 22c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6z'/> \
              </svg>"
              : "data:image/svg+xml;utf-8, \
              <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 48 48'> \
              <path fill='%23FF0000' stroke='%23800000' stroke-width='2' d='M24 4C15.2 4 8 11.2 8 20c0 9 8 16 16 24 8-8 16-15 16-24 0-8.8-7.2-16-16-16zm0 22c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6z'/> \
              </svg>";

            return (
              <Marker
                key={device.idSirena} // Añade una key prop a Marker
                id={device.idSirena}
                position={{
                  lat: device.ubicacion.coordinates[0],
                  lng: device.ubicacion.coordinates[1],
                }}
                onClick={(props, marker) => {
                  setSelectedElement(device);
                  setActiveMarker(marker);
                  setProbando(true);
                }}
                icon={{
                  url: iconColor,
                  scaledSize: new window.google.maps.Size(32, 32)
                }}
              />
            );
          })}

          {selectedElement ? (
            <InfoWindowEx
              visible={showInfoWindow}
              marker={activeMarker}
              onCloseClick={() => {
                setSelectedElement(null);
              }}
            >
              <div>
                {console.log(selectedElement)}
                {selectedElement.catalogo && <p>{selectedElement.id}</p>}
                <p>{selectedElement.nombre}</p>
                <BotonDatos type="button" onClick={(e) => probando(selectedElement)}>
                  Ver datos
                </BotonDatos>
              </div>
            </InfoWindowEx>
          ) : null}
        </Map>
      ) : null}
    </>
  );

};

export default GoogleApiWrapper({
  apiKey: YOUR_GOOGLE_API_KEY_GOES_HERE,
})(Mapa);
