import React from "react";
import { Pie } from "react-chartjs-2";


const PieChart = (props) => {
  const chartOptions = {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: props.nombre,
    }
  };
  const data = {
    labels: ["Red", "Blue", "Yellow"],
    datasets: [
      {
        data: props.data,
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#FF6384", "#36A2EB", "#FFCE56", "#FFCE56"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#FF6384", "#36A2EB", "#FFCE56", "#FFCE56"],
      },
    ],
  };
  return (
    //   <div style={{backgroundColor:"white"}}>

    <Pie data={data} options={chartOptions} />
    //   </div>
  );
};

export default PieChart;
