import React, { useEffect, useState } from "react";
import API from "../../services/api-service"
import { useHistory } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import Swal from 'sweetalert2'
import { TextField } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Select from "../../components/Entradas/Select";
import { LocalizationProvider, DateTimePicker } from "@mui/lab";
import Modal from "../../components/Modal/Modal right-left/index";
import { Main, ContenedorFechas, ContenedorTitulo } from "../Historico/ElementosStyles";
import Tooltip from "react-tooltip";
import ViewListIcon from '@mui/icons-material/ViewList';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import ModalCentro from "../../components/Modal/Modal Centro"

const AdministracionLicencias = () => {
    const history = useHistory();
    const [organizaciones, setOrganizaciones] = useState([]);
    const [organizacion, setOrganizacion] = useState(null);
    const [modalRenovarLicencia, setModalRenovarLicencia] = useState(false);
    const [modalHistorial, setModalHistorial] = useState(false);
    const [fechaInicial, setFechaInicial] = useState(new Date().getTime());
    const [nombreLicencia, setNombreLicencia] = useState("");
    const [estadoLicencia, setEstadoLicencia] = useState("");
    const [vigencia, setVigencia] = useState(null);
    const [historial, setHistorial] = useState([]);
    const [licencias, setLicencias] = useState([]);
    const [isAddLicense, setIsAddLicense] = useState(false);

    useEffect(() => {
        API.getListEmpresas({ organizacion: "constecoin", estado: "habilitado" })
            .then((respuesta) => {
                const data = respuesta.map((item) => {
                    return { value: item.organizacion, label: item.nombre };
                })
                setOrganizaciones(data);
            })
            .catch(err => { console.log(err) })
    }, []);

    const obtenerLicencias = () => {
        API.getLicenses(organizacion)
            .then((res) => {
                setLicencias(res.licencia);
            })
            .catch((error) => console.log(error));
    }

    useEffect(() => {
        obtenerLicencias();
    }, [organizacion]);

    const cambiarFechaI = (fecha) => {
        setFechaInicial(fecha);
    };

    const mostrarModalRenovarLicencia = (data) => {
        setIsAddLicense(false);
        setVigencia(null);
        setNombreLicencia(data.nombre)
        setModalRenovarLicencia(true);
    }

    const renovarLicencia = () => {
        if (vigencia != null && fechaInicial != null) {
            const data = {
                "organizacion": organizacion,
                "nombre": nombreLicencia,
                "fechaInicio": fechaInicial,
                "vigencia": vigencia
            }
            API.renewLicense(data)
                .then((res) => {
                    Swal.fire({
                        icon: "success",
                        text: 'Licencia renovada exitosamente'
                    });
                    setModalRenovarLicencia(false)
                })
                .catch((error) => {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: "Se produjo un error, por favor intente nuevamente"
                    });
                })
        } else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Por favor, complete todos los campos"
            });
        }
    }

    const mostrarModalAgregarLicencia = () => {
        setIsAddLicense(true);
        setNombreLicencia("");
        setModalRenovarLicencia(true);
    }

    const agregarLicencia = () => {
        if (vigencia != null && fechaInicial != null && nombreLicencia != "") {
            const data = {
                "organizacion": organizacion,
                "nombreLicencia": nombreLicencia,
                "fechaInicio": fechaInicial,
                "vigencia": vigencia
            }
            API.createLicense(data)
                .then((res) => {
                    obtenerLicencias();
                    Swal.fire({
                        icon: "success",
                        text: "Licencia creada con éxito"
                    });
                    setModalRenovarLicencia(false);
                }).catch((error) => {
                    console.log(error)
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: "Se produjo un error, por favor intente nuevamente"
                    });
                });
        } else {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Por favor, complete todos los campos"
            });
        }
    }

    const mostrarModalHistorial = (data) => {
        API.getLicensesHistory(organizacion, data.nombre)
            .then((res) => {
                setHistorial(res.historialRenovaciones)
            })
            .catch((error) => console.log(error));
        setNombreLicencia(data.nombre);
        setEstadoLicencia(data.estado);
        setModalHistorial(true);
    }

    return (
        <>
            <Main>
                <ContenedorFechas>
                    <Button onClick={() => { history.goBack() }}>Atras</Button>
                </ContenedorFechas>
                <ContenedorTitulo>Licencias</ContenedorTitulo>
                <ContenedorFechas>
                    <Select
                        message="Organización"
                        data={organizaciones}
                        value={organizacion}
                        setFunction={setOrganizacion}
                        id="outlined-disabled"
                    />
                </ContenedorFechas>
                <div>
                    {(organizacion) ? <>
                        <div style={{ margin: '1rem 0' }}>
                            <Button onClick={() => { mostrarModalAgregarLicencia() }}>Agregar Licencia</Button>
                        </div>
                        <Table responsive>
                            <thead>
                                <tr class="table-active">
                                    <th>Licencias</th>
                                    <th>Fecha de Inicio</th>
                                    <th>Fecha de Vencimiento</th>
                                    <th>Estado</th>
                                    <th>Acción</th>
                                </tr>
                            </thead>
                            <tbody>

                                {(licencias) ? <>
                                    {licencias.map((item) => (
                                        <tr>
                                            <td>{item.nombre}</td>
                                            <td>{new Date(item.fechaInicio).toLocaleDateString()}</td>
                                            <td>{new Date(item.fechaFin).toLocaleDateString()}</td>
                                            <td>{item.estado}</td>
                                            <td>
                                                <Tooltip
                                                    id="renew-tooltip"
                                                    place="top"
                                                    effect="solid"
                                                >
                                                    Renovar Licencia
                                                </Tooltip>
                                                <RestorePageIcon
                                                    data-tip
                                                    data-for="renew-tooltip"
                                                    style={{ marginRight: '1rem', color: "#28a745", cursor: "pointer" }}
                                                    onClick={() => mostrarModalRenovarLicencia(item)}
                                                />
                                                <Tooltip
                                                    id="history-tooltip"
                                                    place="top"
                                                    effect="solid"
                                                >
                                                    Historial
                                                </Tooltip>
                                                <ViewListIcon
                                                    data-tip
                                                    data-for="history-tooltip"
                                                    style={{ marginRight: '1rem', color: "#007bff", cursor: "pointer" }}
                                                    onClick={() => mostrarModalHistorial(item)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </> : null}
                            </tbody>
                        </Table>
                    </> : null}
                </div>
            </Main>
            <Modal isOpen={modalRenovarLicencia} onClose={() => setModalRenovarLicencia(false)} >
                <ContenedorTitulo>{isAddLicense ? 'Agregar Licencia' : 'Renovar Licencia'}</ContenedorTitulo>
                <div style={{ margin: "2rem 0" }}>
                    {isAddLicense ? <>
                        <TextField
                            id="outlined-disabled"
                            label="Licencia"
                            type="text"
                            value={nombreLicencia}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => setNombreLicencia(e.target.value)}
                        />
                    </> : <>
                        <TextField
                            disabled
                            id="outlined-disabled"
                            label="Licencia"
                            type="text"
                            value={nombreLicencia}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => setNombreLicencia(e.target.value)}
                        />
                    </>}
                </div>
                <div style={{ width: 'min', marginBottom: '1rem' }}>
                    <div style={{ margin: "2rem 0" }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                                renderInput={(props) => <TextField {...props} />}
                                label="Fecha Inicial"
                                value={fechaInicial}
                                onChange={cambiarFechaI}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
                <div style={{ margin: "2rem 0" }}>
                    <TextField
                        id="outlined-disabled"
                        label="Meses de Vigencia"
                        type="number"
                        value={vigencia}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={(e) => setVigencia(e.target.value)}
                    />
                </div>
                <Button
                    variant="secondary"
                    onClick={() => setModalRenovarLicencia(false)}
                    style={{ marginRight: '1rem' }}
                >
                    Cancelar
                </Button>
                {isAddLicense ? <>
                    <Button
                        variant="primary"
                        onClick={() => agregarLicencia()}
                    >
                        Agregar
                    </Button>
                </> : <>
                    <Button
                        variant="primary"
                        onClick={() => renovarLicencia()}
                    >
                        Renovar
                    </Button>
                </>}
            </Modal>
            <ModalCentro isOpen={modalHistorial}>
                <Button style={{ position: "absolute", top: "10px", right: "10px" }} onClick={() => setModalHistorial(false)}>
                    X
                </Button>
                <div>
                    <p><b>Organización:</b> {organizacion}</p>
                    <p><b>Licencia:</b> {nombreLicencia}</p>
                    <p><b>Estado:</b> {estadoLicencia}</p>
                </div>
                <Table responsive>
                    <thead>
                        <tr class="table-active">
                            <th>Renovacion</th>
                            <th>Inicio Anterior</th>
                            <th>Fin Anterior</th>
                            <th>Fecha Inicio</th>
                            <th>Fecha Fin</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(historial) ?
                            <>
                                {historial.map((item) => (
                                    <tr>
                                        <td>{new Date(item.fechaRenovacion).toLocaleDateString()}</td>
                                        <td>{new Date(item.fechaInicioAnterior).toLocaleDateString()}</td>
                                        <td>{new Date(item.fechaFinAnterior).toLocaleDateString()}</td>
                                        <td>{new Date(item.nuevaFechaInicio).toLocaleDateString()}</td>
                                        <td>{new Date(item.nuevaFechaFin).toLocaleDateString()}</td>
                                    </tr>
                                ))}
                            </> : <>No hay Datos</>}
                    </tbody>
                </Table>
            </ModalCentro>
        </>
    );
}

export default AdministracionLicencias;
