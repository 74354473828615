import React, { useState, useEffect } from "react";
import API from "../../../services/api-service";
import { useLocation } from "react-router-dom";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ButtonS from "../../../components/Button";
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2'
import {
  ContenedorTitulo,
  Main,
  Titulo,
  BotonDevice,
  ContenedorButton,
  ContenedorEditar
} from "../UsuariosElements";


const EditarUsuario = () => {

  var history = useHistory();

  const [postUsuario, setUsuario] = useState(null);
  const [postNombre, setNombre] = useState(null);
  const [postPassword, setPassword] = useState(null);
  const [postConfirmarPass, setConfirmarPass] = useState(null);
  const [postEmail, setEmail] = useState(null);
  const [postEstado, setEstado] = useState(null);
  const [postRol, setRol] = useState(null);
  const [validacionNombre, setValidacionNombre] = useState(null);

  const query = new URLSearchParams(useLocation().search);
  const obteOrganizacion = query.get("organizacion");
  const getUset = query.get("count");

  useEffect(() => {


    API.obtenerUsuariosIndividual(getUset).then(
      (respuesta) => {
        setUsuario(respuesta[0].usuario)
        setEmail(respuesta[0].email)
        setValidacionNombre(respuesta[0].usuario)
        setNombre(respuesta[0].nombre)
        setEstado(respuesta[0].estado)
        setRol(respuesta[0].rol)
        setPassword(respuesta[0].password)
        setConfirmarPass(respuesta[0].password)
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);


  function sendData() {
    if (postPassword != postConfirmarPass) {
      Swal.fire("Las contraseñas deben ser las mismas", '', 'error')
    } else {

      Swal.fire({
        title: '¿Seguro que quieres editar este Usuario?',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Editar',
        denyButtonText: `No editar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          API.actualizarCuentaUsuario(getUset, postUsuario, postNombre, postEstado, postRol, postEmail, postPassword, validacionNombre).then((respuesta) => {
            if (respuesta.status == true) {
              Swal.fire(`${respuesta.error}`, '', 'error')
            } else {
              Swal.fire(`${respuesta.message}`, '', 'success')
              var url = `/admin/userCount?organizacion=${obteOrganizacion}`
              history.push(url);
            }
          }, (error) => {

          })
        } else if (result.isDenied) {
          Swal.fire('Usuario no editado', '', 'info')
        }
      })
    }

  }

  function Borrar() {
    Swal.fire({
      title: '¿Seguro que quieres eliminar este Usuario?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Eliminar',
      denyButtonText: `No eliminar`,
    }).then((result) => {
      if (result.isConfirmed) {
        API.eliminarUsuario(getUset).then((respuesta) => {
          if (respuesta.status == true) {
            Swal.fire(`${respuesta.error}`, '', 'error')
          } else {
            Swal.fire(`${respuesta.message}`, '', 'success')
            var url = `/admin/userCount?organizacion=${obteOrganizacion}`
            history.push(url);
          }
        })
      }
    })


  }


  return (
    <>      <Main>
      <ContenedorTitulo>
        <Titulo>Editar Usuarios</Titulo>
      </ContenedorTitulo>
      <ContenedorEditar>
        <TextField
          id="outlined-disabled"
          label="Usuario"
          type="text"
          value={postUsuario}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setUsuario(e.target.value)}
        />
      </ContenedorEditar>
      <ContenedorEditar>

        <TextField
          id="outlined-disabled"
          label="Usuario"
          type="text"
          value={postUsuario}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setUsuario(e.target.value)}
        />
      </ContenedorEditar>
      <ContenedorEditar>
        <TextField
          id="outlined-disabled"
          label="Nombre"
          type="text"
          value={postNombre}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setNombre(e.target.value)}
        />
      </ContenedorEditar>
      <ContenedorEditar>
        <TextField
          id="outlined-disabled"
          label="Password"
          type="password"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setPassword(e.target.value)}
        />
      </ContenedorEditar>
      <ContenedorEditar>
        <TextField
          id="outlined-disabled"
          label="Confirmar Password"
          type="password"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setConfirmarPass(e.target.value)}
        />
      </ContenedorEditar>
      <ContenedorEditar>
        <TextField
          id="outlined-disabled"
          label="Email"
          value={postEmail}
          type="text"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setEmail(e.target.value)}
        />
      </ContenedorEditar>



      {
        postEstado != null ? <>
          <ContenedorEditar>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Estado</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={postEstado}
                label="Estado"
                onChange={(e) => setEstado(e.target.value)}
              >

                <MenuItem value="habilitado">Habilitado</MenuItem>
                <MenuItem value="inhabilitado">Inhabilitado</MenuItem>


              </Select>
            </FormControl></ContenedorEditar></> : null
      }



      {postRol != null ? <><ContenedorEditar><FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Rol</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          defaultValue={postRol}
          label="Estado"
          onChange={(e) => setRol(e.target.value)}
        >

          <MenuItem value="usuario">Usuario</MenuItem>
          <MenuItem value="administrador">Administrador</MenuItem>
          <MenuItem value="visualizador">Visualizador</MenuItem>

        </Select>
      </FormControl></ContenedorEditar></> : null}






      <ContenedorButton>
        <ButtonS
          url={`/admin/userCount?organizacion=${obteOrganizacion}`}
          message="Regresar"
          style={BotonDevice}
        >
          Regresar
        </ButtonS>
        <BotonDevice
          onClick={() => {
            sendData();
          }}
        >
          Editar
        </BotonDevice>
        <BotonDevice
          onClick={() => {
            Borrar();
          }}
        >
          Borrar
        </BotonDevice>
      </ContenedorButton>
    </Main></>
  )
}

export default EditarUsuario