import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import { Main, ContenedorDatos, ContenedorFechas, ContenedorTitulo } from "../Historico/ElementosStyles";
import { HelpCircle } from 'react-feather'
import pdfCamaras from "../../assets/doc/camaras.pdf"
import pdfComunicaciones from "../../assets/doc/comunicaciones.pdf"
import pdfSirenas from "../../assets/doc/sirenas.pdf"

const LicenciasTest = () => {
    const history = useHistory();
    const query = new URLSearchParams(useLocation().search);
    const organizacion = query.get("organizacion");

    return (
        <>
            <Main>
                <ContenedorFechas>
                    <Button onClick={() => { history.goBack() }}>Atras</Button>
                </ContenedorFechas>
                <ContenedorTitulo>Licencias</ContenedorTitulo>
                {(organizacion == 'sto_epconst') ?
                    <>
                        <ContenedorDatos>
                            <Table responsive>
                                <thead>
                                    <tr class="table-active">
                                        <th>Licencias</th>
                                        <th>Fecha de Inicio</th>
                                        <th>Fecha de Vencimiento</th>
                                        <th>Certificado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Monitoreo Camaras</td>
                                        <td>20 Marzo 2024</td>
                                        <td>20 Marzo 2025</td>
                                        <td><Button href={pdfCamaras} download="CERTIFICADO_DE_LICENCIA.pdf" size="sm">Descargar</Button></td>
                                    </tr>
                                    <tr>
                                        <td>Monitoreo Sirenas Comunitarias</td>
                                        <td>20 Marzo 2024</td>
                                        <td>20 Marzo 2025</td>
                                        <td><Button href={pdfSirenas} download="CERTIFICADO_DE_LICENCIA.pdf" size="sm">Descargar</Button></td>
                                    </tr>
                                    <tr>
                                        <td>Monitoreo Centro de Comunicaciones</td>
                                        <td>20 Marzo 2024</td>
                                        <td>20 Marzo 2025</td>
                                        <td><Button href={pdfComunicaciones} download="CERTIFICADO_DE_LICENCIA.pdf" size="sm">Descargar</Button></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </ContenedorDatos>
                        <>
                            Si la licencia ha caducado, por favor comuníquese con nuestro equipo de soporte para obtener asistencia
                        </>
                        <ContenedorDatos>
                            <Button href="http://www.soporte.constecoin.com/otrs/customer.pl?_ga=2.157880178.990873719.1639492103-1433824770.1639492103&_gl=1*u41md5*_ga*MTQzMzgyNDc3MC4xNjM5NDkyMTAz*_ga_P7141D6QES*MTYzOTQ5MjEwMS4xLjAuMTYzOTQ5MjEwMS4w">Soporte <HelpCircle size={16} className='mr-75' /></Button>
                        </ContenedorDatos>
                    </> : <></>}
            </Main>
        </>
    );
}

export default LicenciasTest;
