import { FaList, FaRegHeart, FaFile, FaTemperatureHigh } from "react-icons/fa";
import { FiHome } from "react-icons/fi";
import { BiCog } from "react-icons/bi";

const menu = [
  {
    type: "parent",
    name: "Debug & test",
    state: "debug",
    icon: <FaRegHeart />,

    children: [
      {
        type: "router",
        path: "/home",
        name: "Home",
        icon: "nc-icon nc-alien-33",
        layout: "/login",
      },

      {
        path: "/Notificacion",
        name: "Notificacion",
        icon: "nc-icon nc-chart-pie-35",
        layout: "/admin",
      },
      {
        path: "/listDevices",
        name: "Listar dispositivo",
        icon: "nc-icon nc-chart-pie-35",
        layout: "/admin",
      },
      {
        path: "/viewAllDeviceAlcantarilla",
        name: "Dispositivos Alcantarillas",
        icon: "nc-icon nc-chart-pie-35",
        layout: "/admin",
      },
      {
        path: "/statusGateway",
        name: "Estado Gateway",
        icon: "nc-icon nc-chart-pie-35",
        layout: "/admin",
      },
      {
        path: "/creacionGabinete",
        name: "Creacion Gabinete",
        icon: "nc-icon nc-chart-pie-35",
        layout: "/admin",
      },
      {
        path: "/viewHours",
        name: "Filtrar por horas",
        icon: "nc-icon nc-chart-pie-35",
        layout: "/admin",
      },
      {
        path: "/userCount",
        name: "Administrador Cuentas",
        icon: "nc-icon nc-chart-pie-35",
        layout: "/admin",
      },
      {
        path: "/alertas",
        name: "Alertas",
        icon: "nc-icon nc-chart-pie-35",
        layout: "/admin",
      },
      // {
      //   path: "/Gabinete",
      //   name: "Monitoreo Humedad Temperatura",
      //   icon: "nc-icon nc-chart-pie-35",
      //   layout: "/admin",
      // },
      // {
      //   path: "/TablaProcesos",
      //   name: "Tabla Procesos",
      //   icon: "nc-icon nc-chart-pie-35",
      //   layout: "/admin",
      // },
      // {
      //   path: "/CacaoTempSens",
      //   name: "Sensores de Temperatura Cacao",
      //   icon: "nc-icon nc-chart-pie-35",
      //   layout: "/admin",
      // },
      // {
      //   path: "/IoTCacao",
      //   name: "IoT Cacao",
      //   icon: "nc-icon nc-chart-pie-35",
      //   layout: "/admin",
      // },
    ],
  },
  //Pantalla inicial
  //Pantalla inicial
  // {
  //   type: "parent",
  //   name: "General",
  //   state: "general",
  //   icon: <FiHome />,
  //   children: [
  //     {
  //       path: "/home",
  //       name: "Home",
  //       icon: "nc-icon nc-alien-33",
  //       layout: "/admin",
  //     },
  //   ],
  // },

  {
    type: "parent",
    name: "Administración",
    state: "administracion constecoin",
    icon: <BiCog />,
    children: [
      {
        path: "/general",
        name: "General",
        icon: "nc-icon nc-chart-pie-35",
        layout: "/admin",
      },

    ],
  },
];

const menuUser = [
  {
    type: "parent",
    name: "Administración",
    state: "administracion constecoin",
    icon: <BiCog />,
    children: [
      // {
      //   menuShow: false,
      //   path: "/userCount",
      //   name: "Cuentas",
      //   icon: "nc-icon nc-chart-pie-35",
      //   layout: "/admin",
      // },
      {
        menuShow: true,
        path: "/license",
        name: "Licencias",
        icon: "nc-icon nc-chart-pie-35",
        layout: "/admin",
      },
    ],
  },
];

const humitemp = {
  type: "model",
  name: "humi",
  state: "humitemp",
  icon: <FaList />,

  children: [
    // {
    //   menuShow: true,
    //   path: "/AdministracionTemp",
    //   name: "Administración",
    //   icon: "nc-icon nc-chart-pie-35",
    //   layout: "/admin"
    // },

    // {
    //   menuShow: true,
    //   path: "/tablasDispositivosHumitemp",
    //   name: "Dispositivos",
    //   icon: "nc-icon nc-chart-pie-35",
    //   layout: "/admin",
    // },
    // {
    //   menuShow: true,
    //   path: "/tablasGruposHumitemp",
    //   name: "Grupos",
    //   icon: "nc-icon nc-chart-pie-35",
    //   layout: "/admin",
    // },
    // {
    //   menuShow: true,
    //   path: "/monitorizacionTemperatura",
    //   name: "Tablas Temperaturas",
    //   icon: "nc-icon nc-chart-pie-35",
    //   layout: "/admin",
    // },
    // {
    //   menuShow: true,
    //   path: "/dashboardMonitorizacion",
    //   name: "Dashboard Temperaturas",
    //   icon: "nc-icon nc-chart-pie-35",
    //   layout: "/admin",
    // },
    // {
    //   menuShow: true,
    //   path: "/graficosDinamicosTemperatura",
    //   name: "Ubicación Sensores",
    //   icon: "nc-icon nc-chart-pie-35",
    //   layout: "/admin",
    // },
    // {
    //   menuShow: true,
    //   path: "/AlertasHumitemp",
    //   name: "Alertas",
    //   icon: "nc-icon nc-chart-pie-35",
    //   layout: "/admin",
    // },
    // {
    //   menuShow: true,
    //   path: "/IncidentesHumitemp",
    //   name: "Incidencias",
    //   icon: "nc-icon nc-chart-pie-35",
    //   layout: "/admin",
    // },
    // {
    //   path: "/viewHours",
    //   name: "Filtrar por horas",
    //   icon: "nc-icon nc-chart-pie-35",
    //   layout: "/admin",
    // },
  ],
};

const s1 = {
  type: "model",
  name: "humi",
  state: "humitemp",
  icon: <FaList />,

  children: [
    // {
    //   menuShow: true,
    //   path: "/AdministracionTemp",
    //   name: "Administración",
    //   icon: "nc-icon nc-chart-pie-35",
    //   layout: "/admin"
    // },

    {
      menuShow: true,
      path: "/tablasDispositivosHumitemp",
      name: "Dispositivos",
      icon: "nc-icon nc-chart-pie-35",
      layout: "/admin",
    },
    {
      menuShow: true,
      path: "/tablasGruposHumitemp",
      name: "Grupos",
      icon: "nc-icon nc-chart-pie-35",
      layout: "/admin",
    },
    {
      menuShow: true,
      path: "/monitorizacionTemperatura",
      name: "Tablas Temperaturas",
      icon: "nc-icon nc-chart-pie-35",
      layout: "/admin",
    },
    {
      menuShow: true,
      path: "/dashboardMonitorizacion",
      name: "Dashboard Temperaturas",
      icon: "nc-icon nc-chart-pie-35",
      layout: "/admin",
    },
    {
      menuShow: true,
      path: "/graficosDinamicosTemperatura",
      name: "Ubicación Sensores",
      icon: "nc-icon nc-chart-pie-35",
      layout: "/admin",
    },
    {
      menuShow: true,
      path: "/AlertasHumitemp",
      name: "Alertas",
      icon: "nc-icon nc-chart-pie-35",
      layout: "/admin",
    },
    {
      menuShow: true,
      path: "/IncidentesHumitemp",
      name: "Incidencias",
      icon: "nc-icon nc-chart-pie-35",
      layout: "/admin",
    },
  ],
};

const betatemp = {
  type: "model",
  name: "beta",
  state: "betatemp",
  icon: <FaList />,

  children: [
    // {
    //   path: "/AdminBeta",
    //   name: "BetaTEMP",
    //   icon: "nc-icon nc-chart-pie-35",
    //   layout: "/admin",
    // },
    // {
    //   path: "/devicesIoT",
    //   name: "Dispositivos IoT",
    //   icon: "nc-icon nc-chart-pie-35",
    //   layout: "/admin",
    // },

    // {
    //   menuShow: true,
    //   path: "/StatusDispositivos",
    //   name: "Status Dispositivos",
    //   icon: "nc-icon nc-chart-pie-35",
    //   layout: "/admin",
    // },
    // {
    //   menuShow: true,
    //   path: "/VerificacionConexiones",
    //   name: "Conexiones",
    //   icon: "nc-icon nc-chart-pie-35",
    //   layout: "/admin",
    // },
  ],
};

const cadenaFrio = {
  type: "model",
  name: "Cadena",
  state: "cadena frio",
  icon: <FaList />,

  children: [
    // {
    //   menuShow: true,
    //   path: "/CadenaFrio",
    //   name: "General",
    //   icon: "nc-icon nc-chart-pie-35",
    //   layout: "/admin",
    // },
    // {
    //   menuShow: true,
    //   path: "/registroProductos",
    //   name: "Registro productos",
    //   icon: "nc-icon nc-chart-pie-35",
    //   layout: "/admin",
    // },
    // {
    //   menuShow: true,
    //   path: "/Dashboard",
    //   name: "Dashboard ",
    //   icon: "nc-icon nc-chart-pie-35",
    //   layout: "/admin",
    // },
    // {
    //   menuShow: true,
    //   path: "/Dashboard2",
    //   name: "Dashboard gpf ",
    //   icon: "nc-icon nc-chart-pie-35",
    //   layout: "/admin",
    // },
    // {
    //   menuShow: true,
    //   path: "/RegistroBle",
    //   name: "Registro (BLE) ",
    //   icon: "nc-icon nc-chart-pie-35",
    //   layout: "/admin",
    // },
    // {
    //   menuShow: true,
    //   path: "/Vinculacion",
    //   name: "Vinculación",
    //   icon: "nc-icon nc-chart-pie-35",
    //   layout: "/admin",
    // },
    // {
    //   menuShow: true,
    //   path: "/Matches",
    //   name: "Matches",
    //   icon: "nc-icon nc-chart-pie-35",
    //   layout: "/admin",
    // },
  ],
};

const cacaoBeta = {
  type: "model",
  name: "Cadena",
  state: "cadena frio",
  icon: <FaList />,

  children: [
    {
      path: "/formulario",
      name: "Formulario",
      icon: "nc-icon nc-chart-pie-35",
      layout: "/admin",
    },
    {
      path: "/TablaProcesos",
      name: "Tabla Procesos",
      icon: "nc-icon nc-chart-pie-35",
      layout: "/admin",
    },
    {
      path: "/CacaoTempSens",
      name: "Sensores de Temperatura",
      icon: "nc-icon nc-chart-pie-35",
      layout: "/admin",
    },
    {
      path: "/IoTCacao",
      name: "Reporte de históricos",
      icon: "nc-icon nc-chart-pie-35",
      layout: "/admin",
    },
  ],
};

const gabinetes = {
  type: "model",
  name: "Cadena",
  state: "cadena frio",
  icon: <FaList />,

  children: [
    {
      path: "/Gabinete",
      name: "Humedad y Temperatura",
      icon: "nc-icon nc-chart-pie-35",
      layout: "/admin",
    },
  ],
};

const LGT92 = {
  type: "model",
  name: "LGT92",
  state: "LGT92",
  icon: <FaList />,

  children: [
    {
      menuShow: true,
      path: "/LGT92",
      name: "Dashboard Tracking",
      icon: "nc-icon nc-chart-pie-35",
      layout: "/admin",
    },
  ],
};

const Alcantarilla = {
  type: "model",
  name: "Alcantarilla",
  state: "Alcantarilla",
  icon: <FaList />,

  // children: [
  //   {
  //     menuShow: true,
  //     path: "/viewAllDashboard",
  //     name: "Visualizar Dashboard",
  //     icon: "nc-icon nc-chart-pie-35",
  //     layout: "/admin",
  //   },
  // ],
};

const Wt32 = {
  type: "model",
  name: "Wt32",
  state: "Alcantarilla",
  icon: <FaList />,

  children: [
    {
      menuShow: true,
      path: "/wt32",
      name: "Lista Dispositivos",
      icon: "nc-icon nc-chart-pie-35",
      layout: "/admin",
    },
    {
      menuShow: true,
      path: "/viewAllwt32",
      name: "Ubicacion Dispositivos",
      icon: "nc-icon nc-chart-pie-35",
      layout: "/admin",
    },
  ],
};
const Administracion = {
  type: "model",
  name: "Administracion",
  state: "Administracion",
  icon: <FaList />,

  children: [
    // {
    //   menuShow: true,
    //   path: "/userCount",
    //   name: "Administracion",
    //   icon: "nc-icon nc-chart-pie-35",
    //   layout: "/admin",
    // },
    // {
    //   path: "/alertas",
    //   name: "Alertas",
    //   icon: "nc-icon nc-chart-pie-35",
    //   layout: "/admin",
    // },
  ],
};



const Calibracion = {
  type: "model",
  name: "Calibracion",
  state: "Calibracion",
  icon: <FaList />,

  children: [
    // {
    //   menuShow: true,
    //   path: "/calibracion",
    //   name: "Temperatura y Humedad",
    //   icon: "nc-icon nc-chart-pie-35",
    //   layout: "/admin",
    // },
    // {
    //   menuShow: true,
    //   path: "/graficosDinamicosTemperaturaS1",
    //   name: "Ubicacion dispositivos",
    //   icon: "nc-icon nc-chart-pie-35",
    //   layout: "/admin",
    // },
  ],
};

const UbicacionSensores = {
  type: "model",
  name: "Ubicacion Senspres",
  state: "Calibracion",
  icon: <FaList />,
  path: "/ubicacionSensores",
  layout: "/admin",
};
const EventosAlertas = {
  type: "model",
  name: "Eventos Alertas",
  state: "Calibracion",
  icon: <FaList />,
  path: "/events",
  layout: "/admin",
};
const Dispositivos = {
  type: "model",
  name: "Dispositivos",
  state: "Calibracion",
  icon: <FaList />,
  path: "/dispositivos",
  layout: "/admin",
};
const Procesos = {
  type: "model",
  name: "Dispositivos",
  state: "Calibracion",
  icon: <FaList />,
  path: "/procesos",
  layout: "/admin",
};
const Productos = {
  type: "model",
  name: "Dispositivos",
  state: "Calibracion",
  icon: <FaList />,
  path: "/verProductos",
  layout: "/admin",
};
const Dashboard = {
  type: "model",
  name: "Dispositivos",
  state: "Calibracion",
  icon: <FaList />,
  path: "/viewAllDashboard",
  layout: "/admin",
  menuShow: true,
};
const Mapa = {
  type: "model",
  name: "Mapa",
  state: "Calibracion",
  icon: <FaList />,
  path: "/mapa",
  layout: "/admin",
  menuShow: true,
};
const ReporteSirenas = {
  type: "model",
  name: "reporteSirenas",
  state: "Calibracion",
  icon: <FaFile />,
  path: "/viewStatus",
  layout: "/admin",
  menuShow: true,
}
const ReporteAutomatico = {
  type: "model",
  name: "reporteAutomatico",
  state: "Calibracion",
  icon: <FaFile />,
  path: "/automaticReport",
  layout: "/admin",
  menuShow: true,
}
const IotUserManagement = {
  type: "model",
  name: "IotUserManagement",
  state: "Calibracion",
  icon: <FaFile />,
  path: "/iotUsersManagement",
  layout: "/admin",
  menuShow: true,
}
const IotLicenseManagement = {
  type: "model",
  name: "IotLicenseManagement",
  state: "Calibracion",
  icon: <FaFile />,
  path: "/iotLicensesManagement",
  layout: "/admin",
  menuShow: true,
}
const Sirenas = {
  type: "model",
  name: "Sirenas",
  state: "Calibracion",
  icon: <FaList />,
  path: "/monitoreo",
  layout: "/admin",
  menuShow: true,
}
const MonitoreoGeneral = {
  type: "model",
  name: "Sirenas",
  state: "Calibracion",
  icon: <FaList />,
  path: "/monitoreoGeneral",
  layout: "/admin",
  menuShow: true,
}
const LisDispo = {
  type: "model",
  name: "ListDispositivos",
  state: "Calibracion",
  icon: <FaList />,
  path: "/listaDispositivos",
  layout: "/admin",
  menuShow: true,
}
const MoniTemp = {
  type: "model",
  name: "ListTemp",
  state: "Calibracion",
  icon: <FaTemperatureHigh />,
  path: "/temperaturas",
  layout: "/admin",
  menuShow: true,
}
const Alertas = {
  type: "model",
  name: "ListTemp",
  state: "Calibracion",
  icon: <FaTemperatureHigh />,
  path: "/alertas",
  layout: "/admin",
  menuShow: true,
}
const Incidencias = {
  type: "model",
  name: "ListTemp",
  state: "Calibracion",
  icon: <FaTemperatureHigh />,
  path: "/incidencias",
  layout: "/admin",
  menuShow: true,
}
const Filtro = {
  type: "model",
  name: "filtro",
  state: "Calibracion",
  icon: <FaTemperatureHigh />,
  path: "/viewHours",
  layout: "/admin",
  menuShow: true,
}


export { menu };
export { menuUser };
export { humitemp };
export { betatemp };
export { cadenaFrio };
export { cacaoBeta };
export { gabinetes };
export { LGT92 };
export { Alcantarilla };
export { Calibracion };
export { s1 };
export { Wt32 };
export { Administracion };
export { UbicacionSensores };
export { EventosAlertas };
export { Dispositivos };
export { Procesos };
export { Productos };
export { Dashboard };
export { Mapa };
export { Sirenas };
export { MonitoreoGeneral };
export { LisDispo };
export { ReporteSirenas };
export { ReporteAutomatico };
export { IotUserManagement };
export { IotLicenseManagement };
export { MoniTemp };
export { Alertas };
export { Incidencias }
export { Filtro }
