
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Auth from "../../services/auth-service";
import API from "../../services/api-service";
import { Main, ContenedorDatos } from "../Historico/ElementosStyles";
import { Button, Table } from "react-bootstrap";
import { TextField, Checkbox, FormControlLabel } from "@mui/material";
import Select from "../../components/Entradas/Select";
import toast, { Toaster } from "react-hot-toast";

const AutomaticReport = ({ setChangeTable, changeTable }) => {
  const query = new URLSearchParams(useLocation().search);
  const organizacion = query.get("organizacion");
  const localizacion = query.get("localizacion");
  const organizaciones = Auth.getOrganizaciones();
  const [tipoDispositivo, setTipoDispositivo] = useState([]);
  const [dispositivos, setDispositivos] = useState([]);
  const [selectTipoDispositivo, setSelectTipoDispositivo] = useState("");
  const [selectOpcionDeEnvio, setSelectOpcionDeEnvio] = useState("");
  const [selectOpcionDeEnvioDia, setSelectOpcionDeEnvioDia] = useState("");
  const [selectTiempoData, setSelectTiempoData] = useState("");
  const [dispositivosSeleccionados, setDispositivosSeleccionados] = useState(
    []
  );
  const [email, setEmail] = useState("");
  const [asunto, setAsunto] = useState("");
  const [mensaje, setMensaje] = useState("");
  const [currentDevicesPage, setCurrentDevicesPage] = useState(1);
  const [currentSelectedPage, setCurrentSelectedPage] = useState(1);
  const [checked, setChecked] = useState({
    checkboxPdf: false,
    checkboxExcel: false,
    checkboxMaxPorDia: false,
  });

  const itemsPerPage = 5;
  const totalDevices = dispositivos.length;
  const totalSelectedDevices = dispositivosSeleccionados.length;
  const totalPagesDevices = Math.ceil(totalDevices / itemsPerPage);
  const totalPagesSelected = Math.ceil(totalSelectedDevices / itemsPerPage);

  useEffect(() => {
    setDispositivos([]);
    setDispositivosSeleccionados([]);
  }, [localizacion, organizacion]);

  useEffect(() => {
    organizaciones.forEach((org) => {
      if (org.organizacion === organizacion) {
        org.localizacion.forEach((index) => {
          if (index.localiza === localizacion) {
            const nuevoDispositivos = index.configuraciones.map(
              (dispositivo) => ({
                value: dispositivo.catalogo,
                label: dispositivo.nombre,
              })
            );
            setTipoDispositivo(nuevoDispositivos);
          }
        });
      }
    });
  }, [localizacion]);

  useEffect(() => {
    if (selectTipoDispositivo) {
      API.obtenerTESTING(selectTipoDispositivo, organizacion, localizacion)
        .then((res) => {
          let nuevoDispositivos = [];
          res[0].dispositivos.flatMap((dispositivo) => {
            if (res[0].keys) {
              res[0].keys.map((variable) => {
                nuevoDispositivos = [
                  ...nuevoDispositivos,
                  {
                    id: dispositivo.nombre + variable.variable,
                    tipoDispositivo: selectTipoDispositivo,
                    nombre: dispositivo.nombre,
                    dispositivo: dispositivo.dispositivo,
                    variable: variable.variable,
                    tipoVariable: variable.tipo,
                  },
                ];
              });
            } else {
              nuevoDispositivos = [
                ...nuevoDispositivos,
                {
                  id: dispositivo.nombre,
                  tipoDispositivo: selectTipoDispositivo,
                  nombre: dispositivo.nombre,
                  dispositivo: dispositivo.dispositivo,
                  variable: "null",
                  tipoVariable: "null",
                },
              ];
            }
          });
          setDispositivos(nuevoDispositivos);
        })
        .catch((error) => console.error(error));
    }
  }, [selectTipoDispositivo]);

  const validarCamposReporte = () => {
    if (dispositivosSeleccionados.length === 0) {
      return false;
    }
    return true;
  };

  const validarCamposCorreo = () => {
    if (
      email === "" ||
      asunto === "" ||
      mensaje === "" ||
      selectTiempoData === "" ||
      selectOpcionDeEnvioDia === ""
    ) {
      return false;
    }
    return true;
  };

  const agregarDispositivo = (dispositivo) => {
    setDispositivosSeleccionados((prev) => [...prev, dispositivo]);
    setDispositivos((prev) => prev.filter((d) => d.id !== dispositivo.id));
  };

  const eliminarDispositivo = (dispositivo) => {
    setDispositivosSeleccionados((prev) =>
      prev.filter((d) => d.id !== dispositivo.id)
    );
  };

  const getData = async () => {
    let data = [];
    const promises = dispositivosSeleccionados.map(async (dispositivo) => {
      return {
        organizacion: organizacion,
        localizacion: localizacion,
        intervalo: 10,
        dispositivo: dispositivo,
        maxPorDía: checked.checkboxMaxPorDia,
      };
    });
    const results = await Promise.all(promises);
    data = results.filter((result) => result !== null);
    return data;
  };

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setChecked((prev) => ({ ...prev, [name]: checked }));
  };

  const paginateDevices = (pageNumber) => setCurrentDevicesPage(pageNumber);
  const paginateSelectedDevices = (pageNumber) =>
    setCurrentSelectedPage(pageNumber);

  const handleNextDevices = () => {
    if (currentDevicesPage < totalPagesDevices)
      paginateDevices(currentDevicesPage + 1);
  };

  const handlePreviousDevices = () => {
    if (currentDevicesPage > 1) paginateDevices(currentDevicesPage - 1);
  };

  const handleNextSelected = () => {
    if (currentSelectedPage < totalPagesSelected)
      paginateSelectedDevices(currentSelectedPage + 1);
  };

  const handlePreviousSelected = () => {
    if (currentSelectedPage > 1)
      paginateSelectedDevices(currentSelectedPage - 1);
  };

  const renderPaginationButtons = (
    currentPage,
    totalPages,
    paginate,
    handleNext,
    handlePrevious
  ) => {
    const buttons = [];
    const startPage = Math.max(1, currentPage - 1);
    const endPage = Math.min(totalPages, currentPage + 1);

    if (startPage > 1) {
      buttons.push(
        <Button
          key={1}
          onClick={() => paginate(1)}
          size="sm"
          style={{
            margin: "0 3px",
            backgroundColor: "#fff",
            color: "#007bff",
          }}
        >
          1
        </Button>
      );
      if (startPage > 2) buttons.push(<span key="dots1">...</span>);
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <Button
          key={i}
          onClick={() => paginate(i)}
          size="sm"
          style={{
            margin: "0 3px",
            backgroundColor: currentPage === i ? "#007bff" : "#fff",
            color: currentPage === i ? "#fff" : "#007bff",
          }}
        >
          {i}
        </Button>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) buttons.push(<span key="dots2">...</span>);
      buttons.push(
        <Button
          key={totalPages}
          onClick={() => paginate(totalPages)}
          size="sm"
          style={{
            margin: "0 3px",
            backgroundColor: "#fff",
            color: "#007bff",
          }}
        >
          {totalPages}
        </Button>
      );
    }

    return (
      <div>
        <Button
          onClick={handlePrevious}
          disabled={currentPage === 1}
          size="sm"
          style={{
            margin: "0 5px",
            backgroundColor: "#fff",
            color: "#007bff",
          }}
        >
          &lt;
        </Button>
        {buttons}
        <Button
          onClick={handleNext}
          disabled={currentPage === totalPages}
          size="sm"
          style={{
            margin: "0 5px",
            backgroundColor: "#fff",
            color: "#007bff",
          }}
        >
          &gt;
        </Button>
      </div>
    );
  };

  const currentDevices = dispositivos.slice(
    (currentDevicesPage - 1) * itemsPerPage,
    currentDevicesPage * itemsPerPage
  );
  const currentSelectedDevices = dispositivosSeleccionados.slice(
    (currentSelectedPage - 1) * itemsPerPage,
    currentSelectedPage * itemsPerPage
  );

  const programarReporte = async () => {
    if (validarCamposCorreo()) {
      if (validarCamposReporte()) {
        const loadingToast = toast.loading("Cargando...");
        const info = await getData();
        const data = {
          info: info,
          correo: {
            email: email,
            asunto: asunto,
            mensaje: mensaje,
            reportePdf: checked.checkboxPdf,
            reporteExcel: checked.checkboxExcel,
          },
          programacion: {
            frecuencia: selectOpcionDeEnvio,
            dia: selectOpcionDeEnvioDia,
            tiempoData: selectTiempoData,
          },
        };
        API.saveAutomaticReport(data)
          .then(() => {
            toast.success("Guardado con Exitoso");
            changeTable ? setChangeTable(false) : setChangeTable(true);
          })
          .catch((error) => {
            toast.error("Problemas al generar al guardar la configuración");
          })
          .finally(() => {
            toast.dismiss(loadingToast);
          });
      } else {
        toast.error("Agregue al menos un dispositivo");
      }
    } else {
      toast.error("Complete todos los campos");
    }
  };

  return (
    <>
      <div style={{ margin: "1rem 0 0 1.5rem" }}>
        <Select
          message="Tipo de Dispositivo"
          data={tipoDispositivo}
          value={selectTipoDispositivo}
          setFunction={setSelectTipoDispositivo}
          id="outlined-disabled"
        />
      </div>
        {organizacion === "produbanco" ? (
          <Main>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked.checkboxMaxPorDia}
                  onChange={handleChange}
                  name="checkboxMaxPorDia"
                />
              }
              label="Obtener unicamente el valor máximo por día"
            />
          </Main>
        ) : (
          <></>
        )}

        <ContenedorDatos>
          <h5>
            <b>Dispositivos Disponibles</b>
          </h5>
          <Table responsive>
            <thead>
              <tr>
                <th>Dispositivo</th>
                <th>Variable</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              {currentDevices.map((dispositivo, index) => (
                <tr key={index}>
                  <td>{dispositivo.nombre}</td>
                  <td>{dispositivo.variable}</td>
                  <td>
                    <Button onClick={() => agregarDispositivo(dispositivo)}>
                      Agregar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {renderPaginationButtons(
            currentDevicesPage,
            totalPagesDevices,
            paginateDevices,
            handleNextDevices,
            handlePreviousDevices
          )}
        </ContenedorDatos>
        <ContenedorDatos>
          <h5>
            <b>Dispositivos Seleccionados</b>
          </h5>
          <Table responsive class="table table-bordered">
            <thead>
              <tr>
                <th>Dispositivo</th>
                <th>Variable</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              {currentSelectedDevices.map((dispositivo, index) => (
                <tr key={index}>
                  <td>{dispositivo.nombre}</td>
                  <td>{dispositivo.variable}</td>
                  <td>
                    <Button
                      variant="danger"
                      onClick={() => eliminarDispositivo(dispositivo)}
                    >
                      Eliminar
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {renderPaginationButtons(
            currentSelectedPage,
            totalPagesSelected,
            paginateSelectedDevices,
            handleNextSelected,
            handlePreviousSelected
          )}
        </ContenedorDatos>
      
      <Main>
        <Select
          message="Datos del Reporte"
          data={[
            { label: "Ultimas 24 Horas", value: "1" },
            { label: "Ultimos 7 Días", value: "7" },
            { label: "Ultimos 14 Días", value: "14" },
            { label: "Ultimo Mes", value: "31" },
            { label: "Ultimos 2 Meses", value: "32" },
            { label: "Ultimos 3 Meses", value: "33" },
          ]}
          value={selectTiempoData}
          setFunction={setSelectTiempoData}
          id="outlined-disabled"
        />
        <Select
          message="Frecuencia del reporte"
          data={[
            { label: "Semanal", value: "semanal" },
            { label: "Mensual", value: "mensual" },
          ]}
          value={selectOpcionDeEnvio}
          setFunction={setSelectOpcionDeEnvio}
          id="outlined-disabled"
        />
        <>
          {selectOpcionDeEnvio && (
            <>
              {selectOpcionDeEnvio === "semanal" ? (
                <>
                  <Select
                    message="Dia de la Semana"
                    data={[
                      { label: "Lunes", value: "lunes" },
                      { label: "Martes", value: "martes" },
                      { label: "Miercoles", value: "miércoles" },
                      { label: "Jueves", value: "jueves" },
                      { label: "Viernes", value: "viernes" },
                      { label: "Sábado", value: "sábado" },
                      { label: "Domingo", value: "domingo" },
                    ]}
                    value={selectOpcionDeEnvioDia}
                    setFunction={setSelectOpcionDeEnvioDia}
                    id="outlined-disabled"
                  />
                </>
              ) : (
                <></>
              )}
              {selectOpcionDeEnvio === "mensual" ? (
                <>
                  <Select
                    message="Dia del Mes"
                    data={[
                      { label: "1", value: "1" },
                      { label: "2", value: "2" },
                      { label: "3", value: "3" },
                      { label: "4", value: "4" },
                      { label: "5", value: "5" },
                      { label: "6", value: "6" },
                      { label: "7", value: "7" },
                      { label: "8", value: "8" },
                      { label: "9", value: "9" },
                      { label: "10", value: "10" },
                      { label: "11", value: "11" },
                      { label: "12", value: "12" },
                      { label: "13", value: "13" },
                      { label: "14", value: "14" },
                      { label: "15", value: "15" },
                      { label: "16", value: "16" },
                      { label: "17", value: "17" },
                      { label: "18", value: "18" },
                      { label: "19", value: "19" },
                      { label: "20", value: "20" },
                      { label: "21", value: "21" },
                      { label: "22", value: "22" },
                      { label: "23", value: "23" },
                      { label: "24", value: "24" },
                      { label: "25", value: "25" },
                      { label: "26", value: "26" },
                      { label: "27", value: "27" },
                      { label: "28", value: "28" },
                      { label: "29", value: "29" },
                      { label: "30", value: "30" },
                      { label: "31", value: "31" },
                    ]}
                    value={selectOpcionDeEnvioDia}
                    setFunction={setSelectOpcionDeEnvioDia}
                    id="outlined-disabled"
                  />
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </>
        <div>
          <TextField
            label="Correo Electrónico"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ margin: "10px 0" }}
          />
        </div>
        <div>
          <TextField
            label="Asunto"
            value={asunto}
            onChange={(e) => setAsunto(e.target.value)}
            style={{ margin: "10px 0" }}
          />
        </div>
        <div>
          <TextField
            label="Mensaje"
            value={mensaje}
            onChange={(e) => setMensaje(e.target.value)}
            style={{ margin: "10px 0" }}
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked.checkboxPdf}
                onChange={handleChange}
                name="checkboxPdf"
              />
            }
            label="Reporte PDF"
          />
        </div>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked.checkboxExcel}
                onChange={handleChange}
                name="checkboxExcel"
              />
            }
            label="Reporte Excel"
          />
        </div>
        <ContenedorDatos>
          <Button onClick={programarReporte}>Guardar</Button>
          <Toaster position="top-right" reverseOrder={false} />
        </ContenedorDatos>
      </Main>
    </>
  );
};

export default AutomaticReport;
